import { useEffect } from 'react';
import './ContactUs.css';

export default function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="contactUs">
            <div>
                <span>Contactez-nous</span>
                <header>Discutons, Contactez-nous</header>
                <p>Des questions ou des retours ? Envoyez-nous un message, et nous vous répondrons rapidement.</p>
                <div>
                    <input type="text" placeholder="Prénom" />
                    <input type="text" placeholder="Nom" />
                    <input type="email" placeholder="E-mail" />
                    <input type="tel" placeholder="Numéro de Télephone" />
                    <textarea placeholder="Laissez-nous un message" rows={6} />
                    <span>Envoyer un message</span>
                </div>
            </div>
            <div></div>
        </div>
    );

}