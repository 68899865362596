import Model from '../Product/Model';
import ProductTitle from '../../ProductTitle/ProductTitle'
import './ProductsList.css';
import Title from '../../Title/Title';
import { useEffect } from 'react';

export default function ProductsList({ modelsBySubCategory, categoryName, subCategoryName }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="products-list">
            <ProductTitle categoryName={categoryName} subCategoryName={subCategoryName} path={'../../../'} />
            {(
                modelsBySubCategory.map((group) => (
                    <div key={group.subCategoryName}>
                        <Title content={group.subCategoryName} />
                        <div className="models-group">
                            {group.models.map((model) => (
                                <Model key={model.id} model={model} categoryName={categoryName} subCategoryName={subCategoryName}/>
                            ))}
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}
