class Product{
    constructor(id, name ,length, width, thickness, surface, image, ambianceImage,technicalSpecification, code, description, usages=[]) {
        this.id = id;
        this.name = name;
        this.width = width;
        this.length = length;
        this.thickness = thickness;
        this.surface = surface;
        this.image = image;
        this.ambianceImage = ambianceImage;
        this.technicalSpecification = technicalSpecification;
        this.code = code;
        this.description = description;
        this.usages = usages;
      }
}

export default Product;