import { BrowserRouter as Router, Routes, Route, ScrollRestoration  } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import UnderContruction from './components/UnderConstruction/UnderConstruction';
import Products from './components/Products/Products';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
import Decors from './components/Products/Product/Decors/Decors';
import DecorDetails from './components/Products/Product/Decors/Decor/DecorDetails/DecorDetails';
import WhatsappComponent from './components/WhatsappComponent/WhatsappComponent';

function App() {
  return (
    <Router>
    <Header />
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route path="/products" element={<Products/>} />
      <Route path="/products/:categoryName/:subCategoryName" element={<Products />} />
      <Route path="/decors/:categoryName/:subCategoryName/:id" element={<Decors/>} />
      <Route path="/DecorDetails/:categoryName/:subCategoryName/:modelName/:id/:modelId" element={<DecorDetails/>} />
      <Route path="/gallery" element={<UnderContruction/>} />
      <Route path="/aboutUs" element={<UnderContruction/>} />
      <Route path="/contact" element={<ContactUs/>} />
    </Routes>
    <WhatsappComponent/>
    <Footer/>
  </Router>
  );
}

export default App;
