import { useState } from "react";
import { Link } from 'react-router-dom';
import "./Header.css"
const path = `../../../images/logos/`;

export default function Header() {

    const [isDetailsVisible, setIsDetailsVisible] = useState(false);

    const showDetails = () => setIsDetailsVisible(true);
    const hideDetails = () => setIsDetailsVisible(false);

    const [isNavbarVisible, setIsNavbarVisible] = useState(false);

    const toggleNavbar = () => {
        setIsNavbarVisible(!isNavbarVisible);
    };


    return (
        <>
            <div id="header" className="header">
                <img id="logo" src={`${path}logo-fn.png`} />
                <div>
                    <Link className="link" to="/">Accueil</Link>
                    <Link className="link" onMouseEnter={showDetails} to="/products">Produits</Link>
                    <Link className="link" to="/gallery">Galerie</Link>
                    <Link className="link" to="/aboutUs">à propos</Link>
                    <Link className="link" to="/contact">Contact</Link>
                </div>
                <img id="menu" className={`menu-icon ${isNavbarVisible ? "rotate" : ""}`} src="../../../icons/menu-green.png" alt="menu" onClick={toggleNavbar} />
            </div>
            <div className={`hidden-details ${isDetailsVisible ? "visible" : ""}`} onMouseEnter={showDetails} onMouseLeave={hideDetails}>
                <div>
                    <div>
                        <img src="../icons/main-icon-ground.png" />
                        <header>SOL</header>
                    </div>
                    <div>
                        <Link to="/products/parquet/default"><li>Parquet</li></Link>
                        <Link to="/products/gerflex%20(vinyle)/default"><li>Gerflex</li></Link>
                        <Link to="/products/tapis%20paillasson/default"><li>Moquette</li></Link>
                        <Link to="/products"><li>Faux Plancher</li></Link>
                    </div>
                </div>
                <div>
                    <div>
                        <img src="../icons/main-icon-wall.png" />
                        <header>MUR</header>
                    </div>
                    <div>
                    <Link to="/products"><li>Cloison</li></Link>
                    <Link to="/products"><li>Bardage</li></Link>
                    <Link to="/products"><li>Habillage</li></Link>
                    </div>
                </div><div>
                    <div>
                        <img src="../icons/main-icon-ceiling.png" />
                        <header>PLAFOND</header>
                    </div>
                    <div>
                    <Link to="/products"><li>Faux Plafond</li></Link>
                    <Link to="/products"><li>BA-13</li></Link>
                    <Link to="/products"><li>Acoustic</li></Link>
                    </div>
                </div>
            </div>
            <div className={`hidden-navbar ${isNavbarVisible ? "visible" : ""}`} onClick={toggleNavbar}>
                <img id="logo" src={`${path}logo-fn.png`} />
                <Link className="link" to="/">Accueil</Link>
                <Link className="link" to="/products">Produits</Link>
                <Link className="link" to="/gallery">Galerie</Link>
                <Link className="link" to="/aboutUs">à propos</Link>
                <Link className="link" to="/contact">Contact</Link>
            </div>
            <div className={`shadow ${isNavbarVisible ? "shadow-visible" : ""}`} onClick={toggleNavbar}></div>
        </>

    );
}

window.addEventListener("scroll", function () {
    const links = this.document.getElementsByClassName('link');
    if (window.scrollY > 0) {
        this.document.getElementById('header').style.backgroundColor = "rgba(0, 0, 0, 0.7)";
    } else {
        this.document.getElementById('header').style.backgroundColor = '';
    }
});

