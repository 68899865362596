import Title from '../Title/Title';
import { Link } from 'react-router-dom';
import './Home.css';
import LogoSlider from "../LogoSlider/LogoSlider";

import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

export default function Home() {
    const images = [
        'url("./images/home/home-office.jpg")',
        'url("./images/home/products-banner-1.webp")',
        'url("./images/home/home-2.png")',
        'url("./images/home/home-12.jpg")',
        'url("./images/home/home-1.png")',
        
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isSliding, setIsSliding] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    useEffect(() => {
        if (isPaused) return; // Stop the slider if paused

        const interval = setInterval(() => {
            setIsSliding(true);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
                setIsSliding(false);
            }, 500); // Transition duration
        }, 4000); // Interval between slides

        return () => clearInterval(interval);
    }, [isPaused]);

    const handleMouseDown = () => {
        setIsPaused(true); // Pause on mouse down
    };

    const handleMouseUp = () => {
        setIsPaused(false); // Resume on mouse up
    };

    const goToPreviousImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const goToNextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handleClick = (event) => {
        const clickX = event.clientX;
        const divWidth = event.currentTarget.offsetWidth;
        if (clickX < divWidth / 2) {
            goToPreviousImage(); // Left side click
        } else {
            goToNextImage(); // Right side click
        }
    };

    const logos = [
        './images/clients/client-1.png',
        './images/clients/client-2.png',
        './images/clients/client11.png',
        './images/clients/client12.png',
        './images/clients/client-3.png',
        './images/clients/client-6.png',
        './images/clients/client-8.png',
        './images/clients/client-9.png',
        './images/clients/client-10.png',
        './images/clients/client-12.svg',
        './images/clients/client-4.jpg',
        './images/clients/client-13.png',
    ];
    const partners =[
        './images/partners/partner-1-b&w.png',
        './images/partners/partner-2-b&w.png',
        './images/partners/partner-3-b&w.png',
        './images/partners/partner-4-b&w.png',
        './images/partners/partner-5-b&w.png',
        './images/partners/partner-6-b&w.jpg',
        './images/partners/partner-7-b&w.png',
        './images/partners/partner-8-b&w.png',
        './images/partners/partner-9-b&w.png',
        './images/partners/partner-10-b&w.png',
        './images/partners/partner-11-b&w.png',
        './images/partners/partner-12-b&w.png',
        './images/partners/partner-13-b&w.png',
        './images/partners/partner-14-b&w.png',
    ]
    const path = `./images/`;
    return (
        <>
            <div id="home" className="home"  onClick={handleClick} onTouchStart={handleMouseDown} onTouchEnd={handleMouseUp} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} style={{ backgroundImage: images[currentIndex] }}>
                <div className="shadow-image" >
                    <p>
                        Élégance et Durabilité<br />
                        Un écrin sous vos pas, <br />Une étoile scintillante au-dessus, <br />et l’art des murs de vos rêves
                    </p>
                    <span>Découvrir</span>
                </div>
            </div>
            <Title content="Populaires" />
            <div className="popular">
                <motion.div
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <span>PARQUET</span>
                </motion.div>
                <motion.div
                    initial={{ y: -100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <span>GAZON ARTIFICIEL</span>
                </motion.div>
                <motion.div
                    initial={{ y: -100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <span>PLACO PLATRE</span>
                </motion.div>
                <motion.div
                    initial={{ x: 0, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <span>GERFLEX</span>
                </motion.div>
                <motion.div
                    initial={{ x: 0, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <span>paillasson - Moquette</span>
                </motion.div>
                <motion.div
                    initial={{ x: 0, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <span>Bois Extérieur</span>
                </motion.div>
                <motion.div
                    initial={{ x: 0, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <span>Bardage</span>
                </motion.div>
                <motion.div
                    initial={{ x: 0, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <span>store</span>
                </motion.div>
            </div>
            <div>
                <Link to={`/products`} className='see-more'>
                    Voir plus
                </Link>
            </div>
            <div className='banner'>
                <div>
                    <div>
                        <header>CHEZ DECOPA</header>
                        <p>Un écrin sous vos pas, une étoile scintillante au-dessus, et l’art des murs de vos rêves</p>
                        <span>Galerie</span>
                    </div>
                </div>
            </div>
            <Title content="Nos Clients" />
            <LogoSlider logos={logos} nbrImages={5} />
            <Title content="Projets" />
            <div className='gallery'>
                <motion.div
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <header>BUREAUX</header>
                </motion.div>
                <motion.div
                    initial={{ y: -100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <header>CABINETS</header>
                </motion.div>
                <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <header>CLINIQUES</header>
                </motion.div>
                <motion.div
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <header>universités</header>
                </motion.div>
                <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <header>INTERIEUR</header>
                </motion.div>
                <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}>
                    <header>EXTERIEUR</header>
                </motion.div>
            </div>
            <Title content="Nos Partenaires" />
            <div className="partners">
                <LogoSlider logos={partners} nbrImages={5} />
            </div>
            
        </>
    )
}


