import { Link } from 'react-router-dom';
import './Decor.css';
import { useEffect } from 'react';

export default function Decor({product,categoryName,subCategoryName, modelId, modelName}) {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when component mounts
      }, []);


    return (
        <Link key={product.id} className='decor' to={`/DecorDetails/${categoryName}/${subCategoryName}/${modelName}/${product.id}/${modelId}`}>
                <img src={ "../../" +product.image} />
                <div>
                    <header>{product.name}</header>
                    <span>{product.code}</span>
                </div>
                <img src={"../../" + product.ambianceImage} />
        </Link>
    );

}