import { Link } from 'react-router-dom';
import './ProductTitle.css';

export default function ProductTitle({ categoryName, subCategoryName, modelName, productName, path, id }) {
    const resolvedPath = path || "../";
    return (
        <div className='product-title'>
            <Link to={`/products`}>
                <span>{categoryName}</span>
            </Link>
            <img src={resolvedPath + "icons/arrow.png"} alt="arrow" />
            <Link to={`/products/${categoryName}/${subCategoryName}`}>
                    <span>{subCategoryName}</span>
            </Link>
            <img src={resolvedPath + "icons/arrow.png"} alt="arrow" />
            {modelName ? (
                <Link to={`/decors/${categoryName}/${subCategoryName}/${id}`}>
                    <span>{modelName}</span>
                </Link>
            ) : (
                <span>{modelName}</span>
            )}
            <img src={resolvedPath + "icons/arrow.png"} alt="arrow" />
            <span>{productName}</span>
        </div>
    );
}
