import './Decors.css';
import Title from '../../../Title/Title';
import Data from '../../../../data/Models';
import { useParams } from 'react-router-dom';
import Decor from './Decor/Decor';
import { useEffect } from 'react';
import ProductTitle from '../../../ProductTitle/ProductTitle';

export default function Decors({ categoryNameParam, subCategoryNameParam, idParam }) {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when component mounts
    }, []);

    // Retrieve parameters from URL if not provided as props
    const { id: routeId, categoryName: routeCategoryName, subCategoryName: routeSubCategoryName } = useParams();

    // Determine the active parameters to use
    const activeCategoryName = categoryNameParam || routeCategoryName;
    const activeSubCategoryName = subCategoryNameParam || routeSubCategoryName;
    const activeId = idParam || routeId;

    // Find the corresponding model
    const model = Data.models.find((p) => p.id === parseInt(activeId));

    return (
        <div className="decors">
            {model ? (
                <>
                    <ProductTitle 
                    categoryName={activeCategoryName} 
                    subCategoryName={activeSubCategoryName}
                     modelName={model.name}
                    path={'../../../'} />

                    <div>
                        {model.products.map((product) => (
                            <Decor
                                key={product.id}
                                product={product}
                                categoryName={activeCategoryName}
                                subCategoryName={activeSubCategoryName}
                                modelName={model.name}
                                modelId={activeId}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <div className="no-decors">
                    <h2>Aucun décor trouvé.</h2>
                </div>
            )}
        </div>
    );
}
