import './SideNavbar.css';
import { useEffect, useState, useRef } from 'react';

export default function SideNavbar({ categories, selectedCategory, onCategorySelect, onSubCategorySelect }) {
    const [expandedCategoryId, setExpandedCategoryId] = useState(null);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false); // Animation trigger
    const [isVibrating, setIsVibrating] = useState(false); // To control the vibration class
    const [isVibrationStopped, setIsVibrationStopped] = useState(false); // To stop vibration on touch
    const sidebarRef = useRef(null);
    const startTouchX = useRef(0); // To store starting touch position

    // Toggle the expanded state of a category
    const toggleCategory = (category) => {
        const newExpandedCategoryId = expandedCategoryId === category.id ? null : category.id;
        setExpandedCategoryId(newExpandedCategoryId);

        // Notify parent to handle the category selection
        if (newExpandedCategoryId) {
            onCategorySelect(category);
        }
    };

    // Toggle Sidebar visibility
    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    // Close the sidebar if clicked outside of it
    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsSidebarVisible(false);
        }
    };

    // Detect swipe gestures
    const handleTouchStart = (e) => {
        startTouchX.current = e.touches[0].clientX; // Store the starting touch position
        setIsVibrationStopped(true); // Stop vibration permanently after touch
    };

    const handleTouchEnd = (e) => {
        const endTouchX = e.changedTouches[0].clientX; // Get the ending touch position
        const swipeDistance = endTouchX - startTouchX.current; // Calculate the distance moved

        // If the swipe is from left to right, show the sidebar
        if (swipeDistance > 50) {
            setIsSidebarVisible(true);
        }
        // If the swipe is from right to left, hide the sidebar
        else if (swipeDistance < -50) {
            setIsSidebarVisible(false);
        }
    };

    useEffect(() => {
        // Trigger slide-in animation on component mount
        setIsVisible(true);

        // Add event listener to detect clicks outside
        document.addEventListener('mousedown', handleClickOutside);

        // Start vibrating the arrow every 3 seconds only if vibration is not stopped
        if (!isVibrationStopped) {
            const vibrationInterval = setInterval(() => {
                setIsVibrating(true); // Activate vibration
                setTimeout(() => setIsVibrating(false), 300); // Stop vibration after 300ms
            }, 3000); // Vibrate every 3 seconds

            // Clean up vibration interval when component is unmounted or vibration is stopped
            return () => {
                clearInterval(vibrationInterval);
            };
        }

        // Cleanup event listener when component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isVibrationStopped]); // This effect runs when vibration is stopped

    return (
        <>
            <div
                ref={sidebarRef} // Reference to the sidebar container
                className={`side-navbar ${isSidebarVisible ? 'visible' : 'hidden'} ${isVisible ? 'slide-in' : ''}`}
            >
                
                {categories.map((category) => (
                    <div key={category.id}>
                        <div
                            onClick={() => {
                                toggleCategory(category);
                                //setIsSidebarVisible(false);  Close sidebar when a category is clicked
                            }}
                            className={selectedCategory?.id === category.id ? 'selected-category' : ''}
                        >
                            <img
                                src="../../icons/triangle.png"
                                alt="Toggle"
                                className={expandedCategoryId === category.id ? 'rotated' : ''}
                            />
                            <header>{category.name}</header>
                        </div>
                        {expandedCategoryId === category.id && (
                            <div className="subcategories expanded">
                                {category.subCategories.map((subCategory) => (
                                    <span
                                        key={subCategory.id}
                                        onClick={() => {
                                            onSubCategorySelect(subCategory);
                                            setIsSidebarVisible(false); // Close sidebar when subcategory is clicked
                                        }}
                                    >
                                        - {subCategory.name}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div
                className={`arrow ${isSidebarVisible ? 'rotated-arrow' : ''} ${isVibrating ? 'vibrate' : ''}`}
                onClick={toggleSidebar}
                onTouchStart={handleTouchStart} // Add touch event listener
                onTouchEnd={handleTouchEnd}    // Add touch event listener
            >
                <img src="../../icons/arrow.png" alt="Toggle Sidebar" />
            </div>
        </>
    );
}
