
import Model from "../models/Model";
import Product from "../models/Product";


const office = "bureau";
const education = "Education";
const school = "Ecole"
const house = "Maison"
const health = "Santé";
const sports = "Sport";
const commerce = "Commerce";
const storage = "Industrie & Stockage"

//Concept neo
const centro = new Product(1, 'centro', '1195 mm', '154.5 mm', '10 mm', 'Chêne huilé', '../images/models/concept-neo/centro/centro.png', '../images/models/concept-neo/centro/ambiance-centro.png', '', 'PRK601', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);
const moderna = new Product(2, 'moderna', '1195 mm', '154.5 mm', '10 mm', 'Chêne huilé', '../images/models/concept-neo/moderna/moderna.png', '../images/models/concept-neo/moderna/ambiance-moderna.png', '', 'PRK604', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);
const scala = new Product(3, 'scala', '1195 mm', '154.5 mm', '10 mm', 'Chêne huilé', '../images/models/concept-neo/scala/scala.png', '../images/models/concept-neo/scala/ambiance-scala.png', '', 'PRK603', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);
const portello = new Product(4, 'portello', '1195 mm', '154.5 mm', '10 mm', 'Chêne huilé', '../images/models/concept-neo/portello/portello.webp', '../images/models/concept-neo/portello/ambiance-portello.webp', '', 'PRK608', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);
const lima = new Product(5, 'lima', '1195 mm', '154.5 mm', '10 mm', 'Chêne huilé', '../images/models/concept-neo/lima/lima.png', '../images/models/concept-neo/lima/ambiance-lima.png', '', 'PRK606', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);

//Effect
const elbruz = new Product(6, 'Elbruz', '1200 mm', '191 m', '8 m', 'Register Emboss', '../images/models/effect/elbruz.webp', '../images/models/effect/ambiance-elbruz.webp', '', 'PRK911', "Le parquet Elbruz apporte la beauté magnifique des montagnes enneigées dans votre maison, apportant l'atmosphère pure et impressionnante de la nature à votre espace de vie. Alors que vous embarquez pour un voyage vers les sommets couverts de glaciers, il ajoute une touche esthétique à vos espaces.", [house, office]);
const rosso = new Product(7, 'Rosso', '1200 mm', '191 m', '8 m', 'Register Emboss', '../images/models/effect/rosso.webp', '../images/models/effect/ambiance-rosso.webp', '', 'PRK909', "Rosso Parquet propose une solution de décoration unique conçue et inspirée des terres intactes de la nature. Avec sa couleur et sa texture reflétant les brises chaudes du coucher du soleil, c'est un choix idéal pour ajouter de la chaleur et du naturel à votre espace. Ce modèle de parquet unique crée une atmosphère paisible dans votre maison ou votre lieu de travail. Redécouvrez vos espaces de vie avec le modèle Parquet Rosso, inspiré de la beauté intacte de la nature.", [house, office]);
const taurus = new Product(8, 'Toros', '1200 mm', '191 m', '8 m', 'Register Emboss', '../images/models/effect/taurus.webp', '../images/models/effect/ambiance-taurus.webp', '', 'PRK901', "Apportez la richesse unique des tons terre et végétaux à vos espaces de vie ! Ce Parquet vous permet de découvrir les magnifiques couleurs et textures de la nature dans votre maison ou votre lieu de travail. Cette collection unique reflète la vitalité et la chaleur de la nature à l'intérieur, créant une atmosphère sereine dans vos espaces de vie. Le parquet Toros, où vous ressentirez la beauté de la nature à chaque pas, complète parfaitement vos espaces de vie avec une apparence élégante et une base solide.", [house, office]);
const ural = new Product(9, 'Ural', '1200 mm', '191 m', '8 m', 'Register Emboss', '../images/models/effect/ural.webp', '../images/models/effect/ambiance-ural.webp', '', 'PRK907', "Le revêtement de sol stratifié Ural, un ajout harmonieux à la palette invitante de tons de terre chauds de la collection Effect 8mm, offre une occasion unique de vous immerger dans la beauté de la nature à chaque instant. À chaque pas franchi dans l'Oural, vous ressentez un sentiment distinct de connexion avec le monde naturel, insufflant à votre maison une chaleur réconfortante et un véritable sentiment d'authenticité.", [house, office]);
const fuji = new Product(10, 'Fuji', '1200 mm', '191 m', '8 m', 'Register Emboss', '../images/models/effect/fuji.webp', '../images/models/effect/ambiance-fuji.webp', '', 'PRK905', "Fuji Parquet apporte l'attrait enchanteur du coucher de soleil dans vos espaces de vie, vous permettant de créer une atmosphère naturelle avec les tons où le soleil rencontre la terre. Fuji évoque une apparence et une sensation qui vous font presque ressentir la chaleur de la nature dans votre maison ou votre lieu de travail. Avec des tons reflétant la palette de couleurs du coucher de soleil, il vous invite à embrasser les bras de la nature à chaque pas. Transformez votre espace en un environnement chaleureux, accueillant et esthétique avec le parquet Fuji Effect 8MM.", [house, office]);

//Nature Line
const danube = new Product(11, 'Danube', '1200 mm', '191 m', '8 m', 'Natura', '../images/models/nature-line/danube.webp', '../images/models/nature-line/ambiance-danube.webp', '', 'PRK505', 'La danse du bois avec la technologie ! Ressentez le rythme du pays où la valse est née et la paix fluide du Danube chez vous.', [house, office]);
const trendOak = new Product(12, 'Trend Oak', '1200 mm', '191 m', '8 m', 'Natura', '../images/models/nature-line/trend-oak.webp', '../images/models/nature-line/ambiance-trend-oak.webp', '', 'PRK501', "Une décoration innovante qui ouvre l'horizon ! Créez une ambiance différente dans vos espaces de vie avec sa teinte naturelle et chaleureuse qui change des tendances.", [house, office]);
const rhodes = new Product(13, 'Rhodes', '1200 mm', '191 m', '8 m', 'Natura', '../images/models/nature-line/rhodes.webp', '../images/models/nature-line/ambiance-rhodes.webp', '', 'PRK509', "Une décoration innovante qui ouvre l'horizon ! Créez une ambiance différente dans vos espaces de vie avec sa teinte naturelle et chaleureuse qui change des tendances.", [house, office]);
const talya = new Product(14, 'Talya', '1200 mm', '191 m', '8 m', 'Natura', '../images/models/nature-line/talya.webp', '../images/models/nature-line/ambiance-talya.webp', '', 'PRK508', "Le printemps, c'est le réveil de la nature ! Ressentez les nuances du printemps et les couleurs vibrantes de la nature dans vos espaces de vie.", [house, office]);

//Pruva 
const cenova = new Product(15, 'Cenova', '1200 mm', '188 m', '8 mm', 'Natura', '../images/models/pruuva/cenova.webp', '../images/models/pruuva/ambiance-cenova.webp', '', 'PRK224', "Un chêne exquis de Cenova, la patrie des Génois. L'effet du ton chêne clair du motif à chevrons vous est partagé à travers l'interprétation Cenova.", [house, office]);
const parima = new Product(16, 'Parima', '1200 mm', '188 m', '8 mm', 'Natura', '../images/models/pruuva/parima.webp', '../images/models/pruuva/ambiance-parima.webp', '', 'PRK225', "Ornez votre espace avec la finesse d'une corde. Inspiré des cordages des marins, Parima reflète sa personnalité élégante et sa forte présence dans votre espace.", [house, office]);
const patalya = new Product(17, 'Patalya', '1200 mm', '188 m', '8 mm', 'Natura', '../images/models/pruuva/patalya.webp', '../images/models/pruuva/ambiance-patalya.webp', '', 'PRK226', "Une interprétation unique avec le motif Chevron. Faites la différence dans vos sols avec le décor Patalya de la série Pruva.", [house, office]);
const licya = new Product(18, 'Licya', '1200 mm', '188 m', '8 mm', 'Natura', '../images/models/pruuva/licya.webp', '../images/models/pruuva/ambiance-licya.webp', '', 'PRK215', "Faites l'expérience du naturel dans votre espace avec Lycian inspiré de la nature unique et de l'histoire de la Méditerranée.", [house, office]);

//Bella
const acasia = new Product(19, 'Acasia', '1200 mm', '191 m', '8 mm', 'Viva Wood', '../images/models/bella/acasia.webp', '../images/models/bella/ambiance-acasia.webp', '', 'PRK103', "Chaleureux, cosy et naturel ! Explorez la nature dans votre espace avec les lignes délicates et distinctives des tons terre.", [house]);
const daphnia = new Product(20, 'Daphnia', '1200 mm', '191 m', '8 mm', 'Viva Wood', '../images/models/bella/daphnia.webp', '../images/models/bella/ambiance-daphnia.webp', '', 'PRK108', "Il est temps de se rapprocher de la nature ! Reflétez les nuances uniques de la nature sur votre espace.", [house]);
const gardenia = new Product(21, 'Gardenia', '1200 mm', '191 m', '8 mm', 'Viva Wood', '../images/models/bella/gardenia.webp', '../images/models/bella/ambiance-gardenia.webp', '', 'PRK109', "Place à l'élégance ! Ajoutez des touches délicates à vos espaces de vie avec sa décoration chaleureuse et lumineuse.", [house]);
const verona = new Product(22, 'Verona', '1200 mm', '191 m', '8 mm', 'Viva Wood', '../images/models/bella/verona.webp', '../images/models/bella/ambiance-verona.webp', '', 'PRK1110', "Une section de la ville de l'amour éternel de Roméo et Juliette ! Les nuances de gris et de blanc des Arènes de Vérone dans votre espace.", [house]);


//Atlantic 
const classicOak = new Product(23, 'Classic Oak', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/classic-oak.webp', '../images/models/atlantic/ambiance-classic-oak.webp', '../images/models/atlantic/classic-oak-ts.png', '066L', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const goldenOak = new Product(24, 'Golden Oak', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/golden-oak.webp', '../images/models/atlantic/ambiance-golden-oak.webp', '../images/models/atlantic/classic-oak-ts.png', '977L', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const naturlaOak = new Product(25, 'Natural Oak', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/natural-oak.webp', '../images/models/atlantic/ambiance-natural-oak.webp', '../images/models/atlantic/classic-oak-ts.png', '236L', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const oakChevron = new Product(26, 'Oak Chevron', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/oak-chevron.webp', '../images/models/atlantic/ambiance-oak-chevron.webp', '../images/models/atlantic/classic-oak-ts.png', '369M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const oakChevron666 = new Product(27, 'Oak Chevron', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/oak-chevron666.webp', '../images/models/atlantic/ambiance-oak-chevron666.webp', '../images/models/atlantic/classic-oak-ts.png', '666D', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const bartekOak = new Product(28, 'Bartek Oak', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/bartek-oak.webp', '../images/models/atlantic/ambiance-bartek-oak.jpg', '../images/models/atlantic/classic-oak-ts.png', '266M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const goldenOak262 = new Product(29, 'Golden Oak', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/golden-oak262.webp', '../images/models/atlantic/ambiance-golden-oak262.webp', '../images/models/atlantic/classic-oak-ts.png', '262M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const chardonnay = new Product(30, 'Chardonnay', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/chardonnay.webp', '../images/models/atlantic/ambiance-chardonnay.webp', '../images/models/atlantic/classic-oak-ts.png', '269M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const silkOak = new Product(31, 'Silk Oak', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/silk-oak.webp', '../images/models/atlantic/silk-oak.webp', '../images/models/atlantic/classic-oak-ts.png', '639M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const zinc = new Product(32, 'Zinc  ', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/zinc.webp', '../images/models/atlantic/ambiance-zinc.webp', '../images/models/atlantic/classic-oak-ts.png', '993M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);


//Turbo
const veniceTile = new Product(33, 'Venice Tile', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/venice-tile.webp', '../images/models/turbo/ambiance-venice-tile.webp', '../images/models/turbo/turbo-ts.png', '961M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, office]);
const lion = new Product(34, 'Lion', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/lion.webp', '../images/models/turbo/ambiance-lion.webp', '../images/models/turbo/turbo-ts.png', '2303', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, office]);
const cameo = new Product(35, 'Cameo', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/cameo.webp', '../images/models/turbo/ambiance-cameo.webp', '../images/models/turbo/turbo-ts.png', '1300', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, office]);
const aveo = new Product(36, 'Aveo', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/aveo.webp', '../images/models/turbo/ambiance-aveo.webp', '../images/models/turbo/turbo-ts.png', '090S', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, office]);
const agedOak = new Product(37, 'Aged Oak', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/aged-oak.webp', '../images/models/turbo/ambiance-aged-oak.webp', '../images/models/turbo/turbo-ts.png', '967M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, office]);
const agedOak619 = new Product(38, 'Aged Oak', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/aged-oak619.webp', '../images/models/turbo/aged-oak619.webp', '../images/models/turbo/turbo-ts.png', '619M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, office]);
//uni
const blue = new Product(39, 'Solid', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/blue.jpg', '../images/models/turbo/ambiance-uni.jpg', '../images/models/turbo/turbo-ts.png', '772M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, school]);
const green = new Product(40, 'Solid', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/green.jpg', '../images/models/turbo/ambiance-uni.jpg', '../images/models/turbo/turbo-ts.png', '524M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, school]);
const orange = new Product(41, 'Solid', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/orange.jpg', '../images/models/turbo/ambiance-orange.jpg', '../images/models/turbo/turbo-ts.png', '233M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, school]);

//Enfant
const roadTrip = new Product(99, 'Roadtrip ', 0, 0, 0, '', '../images/models/enfant/roadtrip.webp', '../images/models/enfant/ambiance-roadtrip.webp', '../images/models/turbo/turbo-ts.png', '547M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Parfaits pour les espaces de vie animés, ils sont également adaptés aux chambres d’enfants grâce à leur surface facile à nettoyer et résistante aux petits accidents du quotidien. Découvrez tous les atouts de nos rouleaux de vinyle et créez un espace chaleureux pour toute la famille.", [house, school]);
const enfant = new Product(42, 'Enfant', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/enfant/ambiance-enfant.jpeg', '../images/products/gerflex.jpg', '../images/models/turbo/turbo-ts.png', '547M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Parfaits pour les espaces de vie animés, ils sont également adaptés aux chambres d’enfants grâce à leur surface facile à nettoyer et résistante aux petits accidents du quotidien. Découvrez tous les atouts de nos rouleaux de vinyle et créez un espace chaleureux pour toute la famille.", [house, school]);


//gazon artificiel - decoratif
const decoratifGreen7mm = new Product(43,'7 mm/10 mm', '+/- 30 m', '2 - 3 m', '7 mm', '', '../images/models/decoratif-7mm/green.jpg', '../images/models/decoratif-7mm/ambiance-green.jpg', 'ts-img', 'Vert', "", [house]);
const decoratifBlue7mm = new Product(44,'7 mm/10 mm', '+/- 30 m', '2 - 3 m', '7 mm', '', '../images/models/decoratif-7mm/blue.jpg', '../images/models/decoratif-7mm/ambiance-blue.jpg', 'ts-img', 'Bleu', "", [house]);
const decoratifRed7mm = new Product(45,'7 mm/10 mm', '+/- 30 m', '2 - 3 m', '7 mm', '', '../images/models/decoratif-7mm/red.jpg', '../images/models/decoratif-7mm/ambiance-red.jpg', 'ts-img', 'Rouge', "", [house]);


//gazon artificiel - jardin
const jardin18mm = new Product(49,'18 mm', '+/- 30 m', '2 - 3 m', '18 mm', '', '../images/models/sport/iris.jpg', '../images/models/sport/iris.jpg', 'ts-img', '', "", [house, sports]);
const jardin20mm = new Product(50,'20 mm', '+/- 30 m', '2 - 3 m', '20 mm', '', '../images/models/jardin/jardin-20.jpg', '../images/models/jardin/jardin-20.jpg', 'ts-img', '', "", [house, sports]);
const jardin28mm = new Product(51,'28 mm', '+/- 30 m', '2 - 3 m', '28 mm', '', '../images/models/talia/talia-2.jpg', '../images/models/talia/ambiance-talia.jpg','ts-img','', "", [house, sports]);
const jardin30mm = new Product(52,'30 mm', '+/- 30 m', '2 - 3 m', '30 mm', '', '../images/models/castor/castor-2.jpg', '../images/models/castor/ambiance-castor.jpg', 'ts-img', '', "", [house, sports]);


//tapis paillason - spagheti 100
const spaghetiRed = new Product(53,'Rouge', '1 m', '15 m', '12 mm', '', '../images/models/spagheti/spagheti-red.jpg', '../images/models/spagheti/ambiance-spagheti-red.jpg', 'ts-img', 'Spaghetti', "", [house,]);
const spaghetiBlue = new Product(54,'Bleu', '1 m', '15 m', '12 mm', '', '../images/models/spagheti/spagheti-blue.jpg', '../images/models/spagheti/ambiance-spagheti-blue.jpg', 'ts-img', 'Spaghetti', "", [house]);
const spaghetiGreen = new Product(55,'Vert', '1 m', '15 m', '12 mm', '', '../images/models/spagheti/spagheti-green.jpg', '../images/models/spagheti/ambiance-spagheti-green.jpg', 'ts-img', 'Spaghetti', "", [house]);
const spaghetiBlack = new Product(56,'Noir', '1 m', '15 m', '12 mm', '', '../images/models/spagheti/spagheti-black.jpg', '../images/models/spagheti/ambiance-spagheti-black.jpg', 'ts-img', 'Spaghetti', "", [house]);
const spaghetiGray = new Product(57,'Gris', '1 m', '15 m', '12 mm', '', '../images/models/spagheti/spagheti-gris.jpg', '../images/models/spagheti/ambiance-spagheti-gris.jpg', 'ts-img', 'Spaghetti', "", [house]);

//Tapis paillason - zemat
const zematRed = new Product(58,'Rouge', '0,90 m', '15 m', '5 mm', '', '../images/models/zemat/zemat-red.jpeg', '../images/models/zemat/ambiance-zemat-red.jpg', 'ts-img', 'ZMat', "", [house]);
const zematGreen = new Product(59,'Vert', '0,90 m', '15 m', '5 mm', '', '../images/models/zemat/zemat-green.jpeg', '../images/models/zemat/ambiance-zemat-green.jpg', 'ts-img', 'ZMat', "", [house]);
const zematBlue = new Product(60,'Bleu', '0,90 m', '15 m', '5 mm', '', '../images/models/zemat/zemat-blue.jpeg', '../images/models/zemat/ambiance-zemat-blue.jpg', 'ts-img', 'ZMat', "", [house]);
const zematBlack = new Product(61,'Noir', '0,90 m', '15 m', '5 mm', '', '../images/models/zemat/zemat-black.jpeg', '../images/models/zemat/ambiance-zemat-black.jpg', 'ts-img', 'ZMat', "", [house]);
const zematTurq = new Product(69,'Turquoise', '0,90 m', '15 m', '5 mm', '', '../images/models/zemat/zemat-turq.jpeg', '../images/models/zemat/ambiance-zemat-turq.jpg', 'ts-img', 'ZMat', "", [house]);

//Tapis paillason - honey
const honeyRed = new Product(62,'Rouge', '0,90 m', '15 m', '3,8 mm', '', '../images/models/honey/honey-red.jpg', '../images/models/honey/ambiance-honey-red.jpg', 'ts-img', 'Honey', "", [house]);
const honeyGreen = new Product(63,'Vert', '0,90 m', '15 m', '3,8 mm', '', '../images/models/honey/honey-green.jpg', '../images/models/honey/ambiance-honey-green.jpg', 'ts-img', 'Honey', "", [house]);
const honeyBlue = new Product(64,'Bleu', '0,90 m', '15 m', '3,8 mm', '', '../images/models/honey/honey-blue.jpg', '../images/models/honey/ambiance-honey-blue.jpg', 'ts-img', 'Honey', "", [house]);
const honeyBlack = new Product(65,'Noir', '0,90 m', '15 m', '3,8 mm', '', '../images/models/honey/honey-black.jpg', '../images/models/honey/ambiance-honey-black.jpg', 'ts-img', 'Honey', "", [house]);
const honeyGris = new Product(66,'Gris', '0,90 m', '15 m', '3,8 mm', '', '../images/models/honey/honey-gris.jpg', '../images/models/honey/ambiance-honey-gris.jpg', 'ts-img', 'Honey', "", [house]);

//Tapis paillasson - aspro
const asproBlack = new Product(67,'Noir', '1,20 m', '16 m', '2,2 mm', '', '../images/models/aspro/aspro-black.jpg', '../images/models/aspro/ambiance-aspro-black.jpg', 'ts-img', 'Aspro', "", [house]);
const asproGris = new Product(68,'Gris', '1,20 m', '16 m', '2,2 mm', '', '../images/models/aspro/aspro-gris.jpg', '../images/models/aspro/ambiance-aspro-gris.jpg', 'ts-img', 'Aspro', "", [house]);

//Gerflex - Moyen traffic - blacktex 
const safira = new Product(69, "Safira", '+/- 30m', '2 - 3 m', '2,80 mm',"Oak",'../images/models/blacktex/safira.webp', '../images/models/blacktex/safira.webp', '../images/models/blacktex/blacktex.png','974E','', [house, commerce, storage])

//Gerflex - Moyen traffic - tex-style 
const pureOak = new Product(70, "Pure Oak", '+/- 30m', '2 - 3 m', '2,80 mm',"Oak",'../images/models/tex-style/pureOak.webp', '../images/models/tex-style/ambiance-pureOak.webp', '../images/models/tex-style/tex-style.png','010L','', [house, commerce])
const disa = new Product(81, "Disa", '+/- 30m', '2 - 3 m', '2,80 mm',"Oak",'../images/models/tex-style/disa.webp', '../images/models/tex-style/ambiance-disa.webp', '../images/models/tex-style/tex-style.png','979M','', [house, commerce])

//gerflex - grand traffic - xtreme
const mira990 = new Product(71, "Mira", '+/- 22 m', '2 - 3 m', '2 mm',"",'../images/models/xtreme/mira990.webp', '../images/models/xtreme/ambiance-mira990.webp', '../images/models/xtreme/mira990-ts.png','990D','', [house, commerce, storage, sports])
const mira690 = new Product(72, "Mira", '+/- 22 m', '2 - 3 m', '2 mm',"",'../images/models/xtreme/mira690.webp', '../images/models/xtreme/mira690.webp', '../images/models/xtreme/mira990-ts.png','690D','', [house, commerce, storage, sports])
const mira160 = new Product(78, "Mira", '+/- 22 m', '2 - 3 m', '2 mm',"",'../images/models/xtreme/mira160.webp', '../images/models/xtreme/mira160.webp', '../images/models/xtreme/mira990-ts.png','160L','', [house, commerce, storage, sports])

const havannaOak696 = new Product(73, "havanna Oak", '+/- 22 m', '2 - 3 m', '2 mm',"",'../images/models/xtreme/havanna.webp', '../images/models/xtreme/ambiance-havanna.webp', '../images/models/xtreme/mira990-ts.png','696L','', [house, commerce, storage, sports])
const goldenOak690 = new Product(74, "Golden Oak", '+/- 22 m', '2 - 3 m', '2 mm',"",'../images/models/xtreme/goldenOak.webp', '../images/models/xtreme/ambiance-goldenOak.webp', '../images/models/xtreme/mira990-ts.png','690L','', [house, commerce, storage, sports])
const mira440 = new Product(75, "Mira", '+/- 22 m', '2 - 3 m', '2 mm',"",'../images/models/xtreme/roxane.webp', '../images/models/xtreme/ambiance-roxane.webp', '../images/models/xtreme/mira990-ts.png','440M','', [house, commerce, storage, sports])
const mira707 = new Product(76, "Mira", '+/- 22 m', '2 - 3 m', '2 mm',"",'../images/models/xtreme/mira707.webp', '../images/models/xtreme/mira707.webp', '../images/models/xtreme/mira990-ts.png','707M','', [house, commerce, storage, sports])
const mira770 = new Product(77, "Mira", '+/- 22 m', '2 - 3 m', '2 mm',"",'../images/models/xtreme/mira770.webp', '../images/models/xtreme/mira770.webp', '../images/models/xtreme/mira990-ts.png','770M','', [house, commerce, storage, sports])

//gerflex - grand trafic - homogene
const bazlat = new Product(78, "Bazalt", '+/- 20 m', '2 m', '2 mm',"Homogène",'../images/models/homogene/bazalt.jpg', '../images/models/homogene/bazalt.jpg', '../images/models/xtreme/mira990-ts.png','','', [house, commerce, storage, sports])
const almond = new Product(79, "Almond", '+/- 20 m', '2 m', '2 mm',"Homogène",'../images/models/homogene/almond.jpg', '../images/models/homogene/ambiance-almond.jpg', '../images/models/xtreme/mira990-ts.png','','', [house, commerce, storage, sports])
const sky = new Product(80, "Sky", '+/- 20 m', '2 m', '2 mm',"Homogène",'../images/models/homogene/sky.jpg', '../images/models/homogene/ambiance-sky.jpg', '../images/models/xtreme/mira990-ts.png','','', [house, commerce, storage, sports])
const water = new Product(81, "Water", '+/- 20 m', '2 m', '2 mm',"Homogène",'../images/models/homogene/water.jpg', '../images/models/homogene/water.jpg', '../images/models/xtreme/mira990-ts.png','','', [house, commerce, storage, sports])
const koala = new Product(80, "Koala", '+/- 20 m', '2 m', '2 mm',"Homogène",'../images/models/homogene/koala.jpg', '../images/models/homogene/koala.jpg', '../images/models/xtreme/mira990-ts.png','','', [house, commerce, storage, sports])


//Grillage 
const grillage1 = new Model(82, '1 m', '')

const products = [disa, bazlat,almond,sky,water,koala,mira770,mira707,mira440,goldenOak690,havannaOak696,mira160,mira690,mira990,pureOak,safira,zematTurq,asproBlack,asproGris,honeyRed,honeyGreen,honeyBlue,honeyBlack,honeyGris,zematRed,zematGreen,zematBlue,zematBlack,spaghetiBlue,spaghetiGreen,spaghetiBlack,spaghetiGray,spaghetiRed,jardin18mm,jardin20mm,jardin28mm,jardin30mm,decoratifGreen7mm,decoratifBlue7mm,decoratifRed7mm,enfant, green, orange, blue, roadTrip, veniceTile, lion, cameo, aveo, agedOak, agedOak619, naturlaOak, oakChevron, oakChevron666, bartekOak, goldenOak262, chardonnay, silkOak, zinc, goldenOak, classicOak, verona, gardenia, daphnia, acasia, licya, patalya, parima, cenova, centro, moderna, scala, portello, lima, elbruz, rosso, taurus, ural, fuji, danube, trendOak, rhodes, talya];


const models = [
    //Parquet
    new Model(1, "Concept Neo", "./images/models/concept-neo/concept-neo.png", [moderna, portello, scala, lima, centro]),
    new Model(2, "Effect", "./images/models/effect/effect.png", [elbruz, rosso, taurus, ural, fuji]),
    new Model(3, "Nature Line", "./images/models/nature-line/nature-line.png", [danube, trendOak, rhodes, talya]),
    new Model(4, "Pruva", "./images/models/pruuva/pruuva.png", [cenova, parima, patalya, licya]),
    new Model(5, "Bella", "./images/models/bella/bella.png", [acasia, daphnia, gardenia, verona]),

    //gerflex 
    new Model(6, "Atlantic", "./images/models/atlantic/atlantic.webp", [classicOak, goldenOak, naturlaOak, oakChevron, oakChevron666, bartekOak, goldenOak262, chardonnay, silkOak, zinc]),
    new Model(7, "Turbo", "./images/models/turbo/turbo.webp", [veniceTile, lion, cameo, aveo, agedOak, agedOak619, blue, orange, green]),
    new Model(8, "Enfant", "./images/products/gerflex.jpg", [roadTrip, blue, orange, green, enfant]),

    //gerflex - moyen traffic
    new Model(27, "Blacktex","./images/models/blacktex/safira.webp", [safira]),
    new Model(28, "Tex-Style","./images/models/tex-style/ambiance-pureOak.webp", [pureOak, disa]),

    //gerflex - grand traffic
    new Model(29,'Xtreme', './images/models/xtreme/ambiance-mira990.webp',[mira770,mira707,mira440,goldenOak690,havannaOak696,mira690, mira160]),
    new Model(30,'Homogène', './images/models/homogene/ambiance-almond.jpg',[almond,sky,bazlat,water,koala]),
    //Gazon artificiel - Decoratif
    new Model(9, "7 mm",'',[decoratifGreen7mm, decoratifBlue7mm, decoratifRed7mm]),

    //Gazon artificiel - Jardin
    new Model(11, "18 mm",'',[jardin18mm]),
    new Model(12, "20 mm",'',[jardin20mm]),
    new Model(13, "28 mm", './images/models/talia/talia-2.jpg', [jardin28mm]),
    new Model(14, "30 mm", './images/models/castor/castor-2.jpg', [jardin30mm]),

    //Gazon artificiel - Sport
    new Model(16, " 40 mm",'',[]),
    new Model(17, " 50 mm",'',[]),

    //Tapi Paillasson - spagheti
    //new Model(20, "1 m",'', [spaghetiBlue,spaghetiGreen,spaghetiBlack,spaghetiGray,spaghetiRed]),
    new Model(21, "1m/1,20 m",'', [spaghetiBlue,spaghetiGreen,spaghetiBlack,spaghetiGray,spaghetiRed]),

    //Tapi Paillasson - zemat
    //new Model(22, "0,90 m",'', [zematRed,zematGreen,zematBlue,zematBlack,zematTurq]),
    new Model(23, "0,90m/1,20 m",'', [zematRed,zematGreen,zematBlue,zematBlack,zematTurq]),

    //Tapi Paillasson - honey
    //new Model(24, "0,90 m",'', [honeyRed,honeyGreen,honeyBlue,honeyBlack,honeyGris]),
    new Model(25, "0,90m/1,20 m",'', [honeyRed,honeyGreen,honeyBlue,honeyBlack,honeyGris]),

    //Tapis Pailasson - aspro
    new Model(26, "1,20 m",'', [asproBlack,asproGris]),
]



export default { products, models };